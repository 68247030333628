<template>
  <main id="results" ref="results">
      <a href="#" class="back" @click.prevent="$router.go(-1)">tillbaka</a>
      <h1>Följande skolor matchar din sökning</h1>
      <h2>Du fick {{results.length}} svar på din sökning.</h2>
      <article v-for="(program, index) in results" :key="index" class="program">
        <aside>
            <div class="circle">{{program.code}}</div>
        </aside>
        <h2>{{program.program}}</h2>
        <p class="where"><b>{{program.school}}, {{program.municipality}}</b></p>
        <p class="avg">medel: <span v-if="typeof(program.average) === 'number'">{{program.average}}p</span><span v-if="program.average == null">-</span></p>
        <p class="low" v-if="typeof(program.low) === 'number'">lägsta: {{program.low}}p</p>
        <p class="low" v-if="program.low === 'x'">lägsta: alla behöriga sökande blev antagna</p>
        <p class="low" v-if="program.low === 'y'">lägsta: utbildningen har inte antagning på betyg utan enbart test</p>
        <p class="low" v-if="program.low === 'z'">lägsta: ingen antangen på poäng</p>
        <p class="low" v-if="program.low === 'q'">lägsta: placering sker manuellt, ingen antagningspoäng</p>
      </article>
  </main>
</template>

<script>
export default {
    name: 'Results',
    computed: {
        results() {
            return this.$store.state.queryResults;
        }
    }
}
</script>

<style lang="scss">
@import '../scss/variables';

    #results {
        background: white;
        padding: 1rem;
        height: auto !important;

        .back {
            padding: .25rem .5rem;
            border-radius: $radius;
            width: 4rem;
            text-align: center;
            text-decoration: none;
            margin-bottom: 1rem;
            font-size: .8rem;
            border: 1px solid rgba($color: #000, $alpha: .6);
            color: rgba($color: #000, $alpha: .6);

            &:hover {
                 border: 1px solid rgba($color: #000, $alpha: 1);
                color: rgba($color: #000, $alpha: 1);
            }
        }

        .totop {
            border-radius: $radius;
            width: 4rem;
            height: 4rem;
            position: fixed;
            bottom: 0rem;
            right: 1rem;
            border: 1px solid rgba($color: #000, $alpha: .6);
            color: rgba($color: #000, $alpha: .6);

        }

        h1, h2 {
            margin-bottom: 1rem;
        }

        .program {
            display: grid;
            grid-template-columns: 3rem 1fr 1fr;
            gap: .5rem 1rem;
            padding: .5rem 0;
            border-bottom: 1px solid #ccc;
            
            aside {
                grid-column: auto / span 1;
                grid-row: 1 / 5;

                .circle {
                    width: 2.4rem;
                    height: 2.4rem;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-radius: 999rem;
                    background: rgba($color: #000, $alpha: .2);
                }
            }

            > h2 {
                grid-column: auto / span 2;
                font-size: 1.2rem;
                margin: 0;
                padding: 0;
            }

            .where {
                margin: 0;
                padding: 0;
                grid-column: auto / span 2;
            }

            .low {
                margin: 0;
                padding: 0;
                grid-column: auto / span 2;
            }

            .avg {
                margin: 0;
                padding: 0;
                grid-column: auto / span 2;
            }
        }
    }
</style>